/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-size: 16px;
  height: 100vh;
  background: linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(54,54,54,1) 28%, rgba(38,38,38,1) 64%, rgba(29,29,29,1) 85%, rgba(22,22,22,1) 100%);
}

html, body, #root {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /*font-family: 'Poppins', 'Inter', sans-serif!important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  display: flex;
  justify-content: center;
}

.video-responsive iframe {
  top: 0;
  height: 100%;
  width: 95%;
  position: absolute;
  border-radius: 1rem;
}

.loader-path {
  stroke-dasharray: 2500;
  stroke-dashoffset: 1500;
  animation: dash 5s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 822;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.swipeable-list-item__trailing-actions{
  right: 0.8rem!important;
}

.swipeable-list-item__leading-actions{
  left: 0.8rem!important;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
