.pulsing-1 {
    width:80%;
    height:60%;
    border-radius: 50%;
    background: linear-gradient(145deg, rgba(67,67,67,1) 0%, rgba(89,89,89,1) 100%);
    box-shadow: 0 0 0 0 #0004;
    animation:pl1 1s infinite;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
@keyframes pl1 {
    100% {box-shadow: 0 0 0 2rem rgba(89,89,89,1)}
}