.search input {
    color: #F9F9F9!important;
}

/*.search span {*/
/*    color: #F9F9F9!important;*/
/*}*/
.search {
    width: 100%;
}
